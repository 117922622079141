import AuthElements from '@app/Auth'
import CartElements from '@app/Cart'
import ComparisonElements from '@app/Comparison/'
import DistributorElements from '@app/Distributor'
import EventsElements from '@app/Events'
import FormsElements from '@app/Forms'
import Image360Elements from '@app/Image360'
import { install as FiltersElement } from '@app/Filters'
import { install as SimpleFiltersElement } from '@app/FiltersSimple'
import LikesElements from '@app/Likes'
import ListElements from '@app/List'
import SimpleListElements from '@app/ListSimple'
import SearchV2Elements from '@app/Search-v2'
import SearchV3Elements from '@app/Search-v3'
import OrderElements from '@app/Order'
import OtpElements from '@app/Otp'
import PurchaseElements from '@app/Purchase'
import RecipeElements from '@app/Recipe'
import ReviewElements from '@app/Review'
import RoutingElements from '@app/Routing'
import SearchElements from '@app/Search'
import SpecialOffers from '@app/SpecialOffers'
import UiElements from '@app/UiElements'
import MainMenu from '@app/MainMenu'
import ProductAttachmentsComponents from '@app/ProductAttachments'
import TwoFactorComponents from '@app/TwoFactor'
import ResultComponents from '@app/Result'
import ReviewV2Components from '@app/Review-v2'
import GalleryComponents from '@app/Gallery'
import PreorderComponents from '@app/Preorder'
import BlogComponents from '@app/Blog'
import BrandComponents from '@app/Brand'
import LoyaltyComponents from '@app/Loyalty'
import CalculatorComponents from '@app/Calculator'
import SalesComponents from '@app/Sales'
import InstructionsForStarting from '@app/AppLanding'

import ResourceElements from '../resource'

export function install(Vue) {
  Vue.use(AuthElements)
  Vue.use(CartElements)
  Vue.use(ComparisonElements)
  Vue.use(DistributorElements)
  Vue.use(EventsElements)
  Vue.use(FiltersElement)
  Vue.use(FormsElements)
  Vue.use(Image360Elements)
  Vue.use(LikesElements)
  Vue.use(ListElements)
  Vue.use(SearchV2Elements)
  Vue.use(SearchV3Elements)
  Vue.use(OtpElements)
  Vue.use(OrderElements)
  Vue.use(PurchaseElements)
  Vue.use(RecipeElements)
  Vue.use(ResourceElements)
  Vue.use(ReviewElements)
  Vue.use(ResultComponents)
  Vue.use(RoutingElements)
  Vue.use(SimpleFiltersElement)
  Vue.use(SimpleListElements)
  Vue.use(SearchElements)
  Vue.use(SpecialOffers)
  Vue.use(UiElements)
  Vue.use(MainMenu)
  Vue.use(ProductAttachmentsComponents)
  Vue.use(TwoFactorComponents)
  Vue.use(ReviewV2Components)
  Vue.use(GalleryComponents)
  Vue.use(PreorderComponents)
  Vue.use(BlogComponents)
  Vue.use(BrandComponents)
  Vue.use(LoyaltyComponents)
  Vue.use(CalculatorComponents)
  Vue.use(SalesComponents)
  Vue.use(InstructionsForStarting)
}

export default { install }
