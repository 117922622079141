import StarRating from 'vue-star-rating'
import {
  withDefaultProps,
} from '@aspectus/vue-utils'

const ACTIVE_COLOR = '#821D76'
const INACTIVE_COLOR = '#ebeff2'
const BORDER_SIZE = 0
const INCREMENT = 1
const MAX_RATING = 5
const STAR_SIZE = 15
const STAR_POINTS = () => [23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]

const BasePrettyStars = () => withDefaultProps({
  activeColor: ACTIVE_COLOR,
  inactiveColor: INACTIVE_COLOR,
  borderColor: INACTIVE_COLOR,
  borderWidth: BORDER_SIZE,
  maxRating: MAX_RATING,
  increment: INCREMENT,
  showRating: false,
  starSize: STAR_SIZE,
  starPoints: STAR_POINTS,
})(StarRating)

const PrettyStars = BasePrettyStars()

export {
  PrettyStars,
}
