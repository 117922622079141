var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-loader-controller',{attrs:{"resource":_vm.$options.attachmentAndVideoUrl,"immediate":true,"id":_vm.product_id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var receive = ref.receive;
var result = ref.result;
var loading = ref.loading;
return [_c('div',[_vm._l((result && result.items),function(item,index){return [(item.type == 'store.productvideo' && index < _vm.$options.LIMIT)?[_c('ui-youtube-view',{attrs:{"url":item.props.url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var play = ref.play;
return [_c('div',{staticClass:"product-video-view"},[_c('div',{staticClass:"product-video-view__play-btn",on:{"click":function($event){$event.preventDefault();return play($event)}}},[_c('div',{staticClass:"product-video-view__icon icon-play"}),_c('div',{staticClass:"tt-space_ml tt-space_ml--2"},[_c('p',{staticClass:"product-video-view__text"},[_vm._v(_vm._s(_vm._("Play")))])])]),_c('div',{staticClass:"product-video-view__title"},[_c('span',{staticClass:"is-visible-xl"},[_vm._v(_vm._s(item.props.title))])])])]}}],null,true)})]:_vm._e(),(item.type == 'store.productattachment' && index < _vm.$options.LIMIT)?[_c('div',{staticClass:"tt-space_mt tt-space_mt--2-md"},[_c('a',{staticClass:"extension-link",attrs:{"href":item.props.file,"target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"extension-icon",attrs:{"src":item.props.extension.props.icon,"alt":"extension icon","title":""}})]),_c('div',{staticClass:"extension-title ds-caption ds-caption--size_xs"},[_c('span',{staticClass:"is-visible-md"},[_vm._v(_vm._s(item.caption))])])])]:_vm._e()]}),(result && result.total > _vm.$options.LIMIT)?[_c('modal-trigger',{attrs:{"url":"ProductAttachments/Modal","name":"AttachmentModal","attachments":result && result.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"tt-space_mt tt-space_mt--2",on:{"click":open}},[_c('p',{staticClass:"attribute-link tt-link tt-link--styling_default ds-caption ds-caption--light ds-caption--size_2xs ds-caption--size_sm-xl"},[_c('span',{staticClass:"is-disable-md"},[_vm._v(_vm._s(_vm._("Все")))]),_c('span',{staticClass:"is-visible-md"},[_vm._v(_vm._s(_vm._("Все протоколы")))])])])]}}],null,true)})]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }