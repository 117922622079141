<template lang="pug">
  include /mixins
  resource-loader-controller(
    :resource='$options.attachmentAndVideoUrl'
    :immediate='true'
    :id='product_id'
  )
    template(
      #default='{ receive, result, loading }'
    )
      div
        template(v-for='(item, index) in result && result.items')
          template(v-if="item.type == 'store.productvideo' && index < $options.LIMIT")
            ui-youtube-view(
              :url="item.props.url"
            )
              template(#default='{ play }')
                +b.product-video-view
                  +e.play-btn(
                    @click.prevent='play'
                  )
                    +e.icon.icon-play
                    +b.tt-space_ml--2
                      +b.product-video-view__text.P {{ _("Play") }}
                  +e.title
                    +b.is-visible-xl.SPAN {{ item.props.title }}

          template(v-if="item.type == 'store.productattachment' && index < $options.LIMIT")
            +b.tt-space_mt--2-md
              +b.extension-link.A(
                :href="item.props.file"
                target='_blank',
                rel="noopener noreferrer"
              )
                +b.extension-icon.IMG(
                  :src='item.props.extension.props.icon'
                  alt='extension icon'
                )
              +b.extension-title.ds-caption--size_xs
                +b.is-visible-md.SPAN {{ item.caption }}

        template(v-if='result && result.total > $options.LIMIT')
          modal-trigger(
            url='ProductAttachments/Modal',
            name='AttachmentModal',
            :attachments='result && result.items'
          )
            template(#default='{ open }')
              +b.tt-space_mt--2(
                @click='open'
              )
                +b.attribute-link.tt-link--styling_default.ds-caption--light.--size_2xs.--size_sm-xl.P
                  +b.SPAN.is-disable-md {{ _("Все") }}
                  +b.is-visible-md.SPAN {{ _("Все протоколы") }}

</template>

<script>
import {
  attachmentAndVideoUrl,
} from '@api/catalog.service'

const LIMIT = 1200 < window.innerWidth ? 4 : 3

export default {
  name: 'ProductAttachments',
  props: {
    product_id: {
      type: String,
    },
  },
  LIMIT,
  attachmentAndVideoUrl,
  data() {
    return {
      list: [],
    }
  },
}
</script>
