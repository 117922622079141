<template lang="pug">
  include /mixins
  +b.preloader(
    :class='className'
  )
    +b.loader
      +e.circle-svg.SVG(
          xmlns="http://www.w3.org/2000/svg"
          fill='none'
          width='32'
          height='32'
          viewBox='0 0 32 32'
        )
          defs
            linearGradient(
              x1='35%'
              y1='0%'
              x2='100%'
              y2='80%'
              id='preloader-gradient'
            )
              stop(offset='20%' stop-color='var(--theme-relief-1)')
                animate(attributeName="stop-color" dur="2s" values='#821D76; #fff;  #821D76' repeatCount="indefinite")
              stop(offset='76%' stop-color='var(--theme-accent-blue)')
                animate(attributeName="stop-color" dur="2s" values='#fff; #821D76; #fff' repeatCount="indefinite")
          +e.circle.CIRCLE(
            cx="50%"
            stroke='url(#preloader-gradient)'
            cy="50%"
            r='14'
            stroke-width='3'
          )
</template>

<script>
export default {
  name: 'UiLoader',
  props: {
    className: {
      default: 'preloader--withOpacity',
    },
  },
}
</script>
