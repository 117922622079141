const prettifier = str => str
  .replaceAll("Decimal('", '')
  .replaceAll("')", '')
  .replaceAll('None', 'null')
  .replaceAll('False', 'false')
  .replaceAll('True', 'true')
  .replaceAll('\'', '"')

const getConfig = () => JSON.parse(prettifier(window.config_temporary_data))

const getCurrentConfig = key => {
  const config = getConfig()
  return config[key] || null
}

const isConfigAllowed = key => {
  const config = getCurrentConfig(key)
  if (!config) {
    return false
  }
  if (config.availability && config.restriction && 'True' === window.isSuperUser) {
    return true
  }
  if (config.availability && !config.restriction) {
    return true
  }
  return false
}

const HALLOWEEN_KEY = 'allow_halloween'
const HALLOWEEN_CLASSNAME = 'is-halloween'

const createHalloweenConfig = () => {
  const allowed = isConfigAllowed(HALLOWEEN_KEY)
  if (!allowed) return
  const header = document.getElementById('header')
  if (!header) return
  header.classList.add(HALLOWEEN_CLASSNAME)
}

const createDecor = () => {
  createHalloweenConfig()
}

export default createDecor
