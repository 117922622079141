<template lang="pug">
  include /mixins

  +b.control-stack.VALIDATION-OBSERVER.bordered-form(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
    autocomplete='off'
  )
    ui-loader(v-if='isLoad')
    //- pre {{ cart.parameters.promocode_title }}
    //- pre {{ coupon }}
    //- +b.ds-caption--size_md.--size_3md-xl.--medium.tt-space_mb--5.P
    +b.ds-caption--size_md.tt-space_mb--5.P {{ _('Промокод') }}
    row(
      space='none',
      appearance='nowrap'
      v-if='!!(cart.parameters && cart.parameters.promocode_title)'
    )
      cell.full-w(cols='')
        +b.d-control-input--appearance_bordered.--appearance_stacked.control-stack__element.control-stack__element--position_first()
          +e.element(name='coupon' value='test' disabled='true' readonly='readonly' rules='required') {{ cart.parameters.promocode_title }}
          label.d-control-input__label--active.d-control-input__label.d-control-input__label--bound(for='coupon') {{ _("Примененный промокод:") }}

      cell(cols='narrow')
        +b.tt-button--for-input.--appearance_free.d-control-button.control-stack__element.control-stack__element--position_last.BUTTON(
            type='submit',
            @click.prevent='removePromo()'
          )
            +e.title.P.mdi.mdi-close
    row(
      space='none',
      appearance='nowrap'
      v-else
    )
      cell.full-w(cols='')
        +b.d-control-input--appearance_bordered.--appearance_stacked.D-CONTROL-INPUT.control-stack__element.control-stack__element--position_first(
          :input-label='_("Введіть промокод")'
          v-model='form.title'
          id='coupon',
          ref='coupon',
          @input='setUppercase'
          name='coupon',
        )
        .relative(v-if="non_field_errors")
          +b.error-label.LABEL {{ non_field_errors[0] }}
      cell(cols='narrow')
        +b.tt-button--for-input.--appearance_free.d-control-button.control-stack__element.control-stack__element--position_last.LOADER-BTN(
          type='submit',
          :disabled='isLoad || !form.title',
          :action='() => prepareData(valid)'
          :title="_('Применить')"
          :load='isLoad'
        )

</template>

<script>
import { addCartCoupon } from '@api/cart.service'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { mapState, mapActions, mapMutations } from 'vuex'

import { confirmWithPromise } from '@utils/helpers'

export default {
  name: 'Promocode',
  mixins: [FormMixin],
  props: ['phone'],
  data() {
    return {
      form: {
        title: '',
        phone: this.phone,
      },
      isLoad: false,
      non_field_errors: '',
      type: window.coupon,
      labels: {
        promo_exist: this._('ИСПОЛЬЗОВАН ПРОМО-КОД: '),
        promo: this._('Введіть промокод (якщо є)'),
      },
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'coupon',
    ]),
    ...mapState('staff', [
      'user',
    ]),
    isPromoAvailable() {
      return window.authent ? this.user.props.level.props.allow_promocode : window.allow_promocode_for_anonim
    },
  },
  methods: {
    ...mapMutations('cart', [
      'SET_CART',
    ]),
    ...mapActions('cart', [
      'changeCart',
      'getCart',
    ]),
    async prepareData(valid) {
      if (!this.form.title) return
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    setUppercase() {
      this.form.title = this.form.title.toUpperCase()
    },
    send(data) {
      return addCartCoupon.execute({}, data)
        .then(({ data: { props } }) => {
          // this.SET_CART({ payload: data })

          const removeCouponMeta = {
            message: {
              title: this._('Remove Coupon success title'),
              text: this._('Remove Coupon success text'),
            },
          }
          // if (!(props && props.title)) {
          //   AnswerMessage(removeCouponMeta, this)
          // }
          this.getCart()
          this.$emit('phomo-change')
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async removePromo() {
      const question = this._('Вы действительно хотите отменить примененный промокод?')

      await confirmWithPromise(question, this)
      this.send({ title: null })
    },
  },
}
</script>
